@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;;
}

code {
  
}


.btn-primary{
  border-radius: 5px!important;
  background-color: #0c4da2!important;
  border-color:#0c4da2!important;
}
a{
  color: #0c4da2;
}
a:hover{
  color: #0c4da2;
}