.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a {
  text-decoration: none !important;
  font-style: none;
  color: inherit;
}

h1 {
  margin: 0px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.active {
  background-color: purple;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 20px;
  transition: ease-in-out 0.5s;
}

.main {
  margin-left: 250px;
  font-size: 28px;
  background: #dae4f0;
  height: 100vh;
  padding: 0px 30px;
  transition: ease-in-out 0.5s;
  height: -webkit-fill-available;
  min-height: 100vh;
}

.menu-btn {
  position: absolute !important;
  z-index: 1;
  margin-top: 1rem !important;
}

.side-open {
  margin-left: 0px !important;
}

.overflow-container {
  height: calc(100vh - 207px);
  width: 100%;
  overflow-y: auto;

}
.appbar{
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  bottom: 80px;
  padding: 10px 30px;
  margin-top: auto;
  z-index: 999999;
}
.appbar h4{
  font-size: 16px;
  
}
.btn-primary{
  color: #fff;
  background-color: #0c4da2;
  border-color: #0c4da2;
}
@media screen and (max-width: 768px) {
  .sidebar {
    margin-left: -250px;
  }

  .main {
    margin-left: 0px;
    padding: 0px 10px;
  }

  .header {
    margin-left: 25px !important;
  }

  .overflow-container {
    height: calc(100vh - 220px);
  }
}
